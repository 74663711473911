<template>
<div class="app-main__inner">
  <div class="row">
    <div class="col-md-12">
        <div class="app-page-title dashboard-title">
            <div class="page-title-wrapper">
                <div class="page-title-heading">
                    <div class="page-title-icon">
                        <i class="pe-7s-volume1 icon-gradient bg-happy-itmeo">
                        </i>
                    </div>
                    <div>Listening Help</div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="tabs-row">
        <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active" data-toggle="tab" @click="activeTab ='WFD'">Write from Dictation</a></li>
            <li class="nav-item"><a class="nav-link" data-toggle="tab" @click="activeTab= 'SST'">Summarised Spoken Text</a></li>
            <!-- <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab-3">Listening Mode</a></li> -->
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
      <div class="col-12 pdReader" v-if="activeTab=='WFD'" >
          <vue-pdf-app :config="config" :title="true" pdf="pdf/WritefromdictationAnswers.pdf"></vue-pdf-app>
      </div>
      <div class="col-12" v-if="activeTab=='SST'">

      </div>
  </div>
</div>
</template>
<script>
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
export default {
    components: {
    VuePdfApp
    },
    data(){
        return {
            activeTab:'WFD',
            config: {
                toolbar: {
                    toolbarViewerRight: { 
                        presentationMode: true,
                        openFile: false,
                        print: false,
                        download: false,
                        viewBookmark: true,

                        }
                    }
            },
        }
    },
    methods:{
    }
}
</script>
<style scoped>
.tabs-row .nav-tabs{
    margin: 0 0 30px 0;
}
.pdReader
{
min-height:80vh;
border:1px solid #ccc;
padding: 0px;
}
</style>